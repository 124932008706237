import { makeStyles } from "@mui/styles";
import { COLOR_LIGHT_RED } from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    minWidth: 400,
    minHeight: 20,
    paddingBottom: 2,
  },
  dialogContentContainer: {
    disply: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
    width: "85%",
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 6,
  },
  dialogButtons: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "flex-end",
  },
  dialogButton: {
    marginRight: 10,
    fontSize: 14,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  cellFocus: {
    cursor: "pointer",
    width: "20vw",
  },
  resize: {
    width: "60%",
    marginLeft: "8px",
  },
  dataMgtSelect: {
    display: "flex",
    fontSize: 16,
  },
  datePicker: {
    padding: 4,
    width: 120,
    height: 26,
  },
  timePicker: {
    padding: 4,
    width: 90,
    height: 26,
  },
  recDatePicker: {
    width: 120,
    height: 26,
  },
  boderColor: {
    border: `1px solid ${COLOR_LIGHT_RED}`,
  },
  disabledBox: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  deleteButton: {
    cursor: "pointer",
    color: theme.palette.redAlert[800],
    fontSize: 16,
    marginTop: -0.5,
    height: 18,
    padding: "0",
    paddingLeft: "5px",
  },
}));

export default useStyles;
