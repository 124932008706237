import { useState, useEffect } from "react";
import SbuxTranslate from "../SbuxTranslate";
import { Tabs, Tab, Box, TabScrollButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import useStyles from "./styles";

const SbuxSubTabs = ({
  tabList,
  tabId,
  handleTabChange,
  width,
  maxWidth,
  disabled = false,
  children,
  handleTabClick,
}) => {
  const [activeTabId, setActiveTabId] = useState(tabId);
  const classes = useStyles();
  const theme = useTheme();
  const tabScrollButton = withStyles((theme) => ({
    root: {
      width: "16px !important",
      backgroundColor: `${theme.palette.darkGray.main} !important`,
      color: "#ffff",
      fontSize: "20px",
      overflow: "hidden",
      transition: "width .1s",
      "&.Mui-disabled": {
        width: 0,
      },
    },
  }))(TabScrollButton);

  const handleTabOnChange = (event, newValue) => {
    if (handleTabChange) {
      handleTabChange(newValue);
      setActiveTabId(newValue);
    }
  };

  if (activeTabId !== tabId) {
    setActiveTabId(tabId);
  }

  return (
    <Box>
      <Tabs
        sx={{
          "&.MuiTabs-root": {
            minHeight: "26px !important",
            fontWeight: 600,
            width: width ? width : "100%", // prev: 32vw
            maxWidth: maxWidth ?? "100%",
          },
          opacity: disabled ? 0.65 : 1,
          pointerEvents: disabled ? "none" : "initial",
        }}
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons="auto"
        ScrollButtonComponent={tabScrollButton}
        value={activeTabId}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        onChange={handleTabOnChange}
      >
        {!children &&
          tabList &&
          tabList.length > 0 &&
          tabList.map((tab, index) => {
            return (
              <Tab
                value={tab.value}
                label={<SbuxTranslate>{tab.name}</SbuxTranslate>}
                key={index}
                className={classes.rootTab}
                disabled={tab.disabled}
                onClick={() => {
                  handleTabClick(tab);
                }}
              />
            );
          })}
        {children && children}
      </Tabs>
    </Box>
  );
};

export default SbuxSubTabs;
