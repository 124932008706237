import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  multiline: {
    "& .MuiInputBase-input": {
      height: "85px !important",
    },
    "& .MuiOutlinedInput-root": {
      height: "auto",
      minHeight: 50,
      padding: "0.5rem",
    },
  },
  counter: {
    margin: "0 1px 0 auto",
    fontSize: 12,
  },
  holder: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
  }
}));

export default useStyles;
