import React, { useState } from "react";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import MainHoursEdit from "../mainHours/MainHoursEdit";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import { getLastUpdated } from "../../../utils";
import { ellipse } from "../../../../../utils/stringUtil";
import useStyles from "../../styles";

const MainHoursMasterRow = ({ row, handleEditRowClick }) => {
  const classes = useStyles();

  const dialogConfig = {
    title: "Details",
  };

  const handleEditClick = () => {
    handleEditRowClick(row);
  };

  return (
    <>
      <SbuxStyledTableRow
        // sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={handleEditClick}
      >
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["hoursCode"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {ellipse(row["hoursName"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {ellipse(row["description"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {getLastUpdated(row)}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default MainHoursMasterRow;
