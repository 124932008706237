import { useSelector, useDispatch } from "react-redux";
import SbuxSelect from "../../SbuxSelect";
import { useState } from "react";
import { getSelectedTimezone } from "../../../services/settings";
import { Box } from "@mui/material";
import useStyles from "../styles";
import SbuxTranslate from "../../SbuxTranslate";
import { Divider } from "@mui/material";

const TimeZoneList = () => {
  const dispatch = useDispatch();
  const {
    globalConfig: { globalConfigUi },
    settings: { timezone },
  } = useSelector((state) => state);
  const classes = useStyles();
  const [timeZoneValue, setTimeZoneValue] = useState(timezone);
  const handleTimeZoneChange = async (timezone) => {
    setTimeZoneValue(timezone);
    await dispatch(getSelectedTimezone(timezone));
  };
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
      }}
    >
      <Box>
        <SbuxSelect
          label="Timezone"
          menuItems={globalConfigUi.timezones}
          defaultValue={timeZoneValue}
          value={timeZoneValue}
          handleSelectChange={(timezone) => handleTimeZoneChange(timezone)}
        />
      </Box>

      <Divider sx={{ marginTop: 1 }} />
    </Box>
  );
};
export default TimeZoneList;
