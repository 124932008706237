import { makeStyles } from "@mui/styles";
import { grid } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
    gridRowGap: 10,
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  section: {
    marginTop: 20,
    width: "100%",
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& > .MuiBox-root": {
      maxWidth: '350px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: "100%",
        width: '100%'
      },
    },
    "& > .MuiBox-root.fullWidth": {
      maxWidth: "100%"
    }
  },
}));

export default useStyles;
