import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: "5px",
    paddingLeft: "5px",
    flexWrap: "wrap",
    marginTop: "0px",
  }
}));

export default useStyles;
