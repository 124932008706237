import React, { useEffect, useState } from "react";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SbuxAutocomplete from "../../../../../components/SbuxAutoComplete";
import { getDays, getTime } from "../../../../../utils/timeUtil";
import {
  Checkbox,
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import moment from "moment";
import useStyles from "../../styles";

const MainHoursConfigTableRow = ({
  row,
  handleDeleteRowClick,
  handleEditRowClick,
}) => {
  const classes = useStyles();
  const [start, setStart] = useState(row.start);
  const [end, setEnd] = useState(row.end);
  const [day, setDay] = useState(row.day);
  const [checked, setChecked] = useState(
    row.hasOwnProperty("isOpenFor24Hours") ? row.isOpenFor24Hours : false
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const toggleDeleteDialogOpen = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleDeleteCurrentRow = () => {
    handleDeleteRowClick(row);
  };

  /**
   * Build a new row instance using values from current state
   * @return {object} row instance
   */
  const buildCurrentRow = () => {
    return {
      ...row,
      start,
      end,
      day,
      isOpenFor24Hours: checked,
    };
  };

  /**
   * Handle the event when a day value changes
   * @param  {object} day               selected day value
   */
  const handleDayChange = (day) => {
    if (day !== day.value) {
      const newRow = buildCurrentRow();

      newRow.day = day.value;
      setDay(newRow.day);

      handleEditRowClick(newRow);
    }
  };

  /**
   * Handle the event when the store hours are set 24hrs
   * @param  {Event} value               event object
   */
  const handleOpen24Hours = (value) => {
    if (checked !== value.target.checked) {
      const newRow = buildCurrentRow();

      newRow.isOpenFor24Hours = value.target.checked;
      setChecked(newRow.isOpenFor24Hours);
      if (value.target.checked) {
        newRow.start = "00:00";
        newRow.end = "00:00";
      } else {
        newRow.start = "09:00";
        newRow.end = "17:00";
      }

      setStart(newRow.start);
      setEnd(newRow.end);

      handleEditRowClick(newRow);
    }
  };

  /**
   * Handles the event when start time changes
   * @param  {string} value               start time value
   */
  const handleStarTimeChange = (value) => {
    const formattedValue = moment(value, ["hh:mm A"]).format("HH:mm");
    if (start !== formattedValue) {
      const newRow = buildCurrentRow();

      newRow.start = formattedValue;
      setStart(newRow.start);
      if (formattedValue === "00:00" && end === "00:00") {
        newRow.isOpenFor24Hours = true;
        setChecked(newRow.isOpenFor24Hours);
      }

      handleEditRowClick(newRow);
    }
  };

  /**
   * Handles the event when end time changes
   * @param  {string} value               end time value
   */
  const handleEndTimeChange = (value) => {
    const formattedValue = moment(value, ["hh:mm A"]).format("HH:mm");
    if (end !== formattedValue) {
      const newRow = buildCurrentRow();

      newRow.end = formattedValue;
      setEnd(newRow.end);
      if (formattedValue === "00:00" && start === "00:00") {
        newRow.isOpenFor24Hours = true;
        setChecked(newRow.isOpenFor24Hours);
      }

      handleEditRowClick(newRow);
    }
  };

  const dialogConfig = {
    title: "Delete",
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell padding={"0px"}>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={toggleDeleteDialogOpen}
            className={classes.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            id="days"
            options={getDays()}
            type="text"
            value={day}
            handleDayChange={(newValue) => handleDayChange(newValue)}
            handleTimeChange={null}
          />
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            options={getTime(null)}
            type="time"
            value={moment(start, ["HH:mm"]).format("hh:mm A")}
            handleDayChange={null}
            handleTimeChange={(newValue) => handleStarTimeChange(newValue)}
            compareTo="endTime"
            compareToValue={moment(end, ["HH:mm"]).format("hh:mm A")}
            isOpen24Hours={checked}
          />
        </SbuxStyledTableCell>
        <SbuxStyledTableCell padding={"0px"}>
          <SbuxAutocomplete
            options={getTime(end)}
            type="time"
            value={moment(end, ["HH:mm"]).format("hh:mm A")}
            handleDayChange={null}
            handleTimeChange={(newValue) => handleEndTimeChange(newValue)}
            compareTo="startTime"
            compareToValue={moment(start, ["HH:mm"]).format("hh:mm A")}
            isOpen24Hours={checked}
          />
        </SbuxStyledTableCell>

        <SbuxStyledTableCell padding={"0px"}>
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            disableFocusRipple
            disableRipple
            checked={checked}
            onChange={handleOpen24Hours}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 22 },
              color: "#006241",
              "&.Mui-checked": {
                color: "#006241",
              },
            }}
          />
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
      {deleteDialogOpen && (
        <SbuxDialog2
          open={deleteDialogOpen}
          config={dialogConfig}
          handleOnClose={toggleDeleteDialogOpen}
        >
          <DialogContent dividers>
            <Typography variant="h5">{`Are you sure you want to delete?`}</Typography>
          </DialogContent>

          <DialogActions>
            <Box className="actionContainer">
              <Button
                variant="contained"
                size="medium"
                onClick={handleDeleteCurrentRow}
              >
                Okay
              </Button>
              <Button
                variant="outlined"
                size="medium"
                onClick={toggleDeleteDialogOpen}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </SbuxDialog2>
      )}
    </>
  );
};

export default MainHoursConfigTableRow;
