import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    minHeight: "auto",
  },
  heading: {
    paddingTop: 10,
    paddingBottom: 5,
  },
  linkColumn: {
    maxHeight: 120,
    overflowY: "auto",
    "&.sb-dialog--contentBody": {
      padding: "2rem 3.2rem 0rem 3.2rem !important",
    },
  },
  linkButton: {
    fontSize: 14,
    color: "#00754A",
    borderBottom: "2px solid #006241",
    "&.py2": {
      paddingTop: "0.5rem !important",
      paddingBottom: "0rem !important",
    },
    "&.mb2": {
      marginBottom: "0rem !important",
    },
  },
  "&.button:focus": {
    outline: "none !important",
  },
  loading: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    left: "22%",
    position: "relative"
  },
  sbuxLogo: {
    height: 300,
    width: 300,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
}));

export default useStyles;
