import { Box } from '@mui/material';
import SbuxCard from "../components/SbuxCard";
import { useSelector } from "react-redux";
import Content from "../features/content";
import ContentTabs from "../features/content/ContentTabs";
import useStyles from "./styles";

const ContentCrate = () => {
  const classes = useStyles();
  const { status, instances, connectedInstance } = useSelector(
    (state) => state.userInstance
  );

  return (
    <Box className={classes.rightContent}>
      <SbuxCard>
        {connectedInstance ? (
          <Content />
        ) : (
          (status === "success" || status === "rejected") &&
          instances.length === 0 && <ContentTabs />
        )}
      </SbuxCard>
    </Box>
  );
};

export default ContentCrate;
