import { useCallback, useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Typography,
  AppBar,
  Container,
  Toolbar,
  SvgIcon,
  Drawer,
  Divider
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "./styles";

const NavDrawer = ({ cognitoUserId, children }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        className={classes.drawerMenu}
        onClick={toggleDrawer}
      >
        <MenuIcon sx={{ fontSize: 30 }} />
      </IconButton>

      {openDrawer && (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}>
          <Box className={classes.drawerContainer}>

            <Box className={classes.drawerContent}>
              {children}
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default NavDrawer;
