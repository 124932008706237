import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteMediaMgt } from "../../../../services/mediaMgt";
import useStyles from "../styles";

const MediaMgtDeleteDialog = ({ row, title, name, open, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOkayClick = async () => {
    const deleteObj = {
      mediaId: row.mediaId,
      language: row.language,
      filePath: row.filePath,
      languageCode: row.languageCode,
      type: row.type,
    };
    await dispatch(deleteMediaMgt(deleteObj));
  };

  const dialogConfig = {
    title,
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <SbuxDialog2 open={open} config={dialogConfig} handleOnClose={toggleOpen}>
      <DialogContent dividers>
        <Typography variant="h5">
          <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
          <span>{`${name}?`}</span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button variant="contained" size="medium" onClick={handleOkayClick}>
            <SbuxTranslate>{`Okay`}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleOpen}>
            <SbuxTranslate>{`Cancel`}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default MediaMgtDeleteDialog;
