import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import useStyles from "./styles";

const ClosureMsgWeeklyCheckbox = ({
  handleCheckboxChange,
  weeklyRecDays,
  isEdit = false,
}) => {
  const classes = useStyles();

  return (
    <>
      {isEdit ? (
        <FormControl
          className={classes.checkboxList}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("MO")}
                  onChange={handleCheckboxChange}
                  name={"MO"}
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("TU")}
                  onChange={handleCheckboxChange}
                  name={"TU"}
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("WE")}
                  onChange={handleCheckboxChange}
                  name={"WE"}
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("TH")}
                  onChange={handleCheckboxChange}
                  name={"TH"}
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("FR")}
                  onChange={handleCheckboxChange}
                  name={"FR"}
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("SA")}
                  onChange={handleCheckboxChange}
                  name={"SA"}
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("SU")}
                  onChange={handleCheckboxChange}
                  name={"SU"}
                />
              }
              label="Sunday"
            />
          </FormGroup>
        </FormControl>
      ) : (
        <FormControl
          className={classes.checkboxList}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"MO"} />}
              label="Monday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"TU"} />}
              label="Tuesday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"WE"} />}
              label="Wednesday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"TH"} />}
              label="Thursday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"FR"} />}
              label="Friday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"SA"} />}
              label="Saturday"
            />
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} name={"SU"} />}
              label="Sunday"
            />
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export default ClosureMsgWeeklyCheckbox;
