import React from "react";
import { Divider } from "@mui/material";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SbuxTranslate from "../../SbuxTranslate";
import SbuxSelect from "../../SbuxSelect";
import { getLocale } from "../../../services/settings";
import languageConfig from "../config/language.json";
import useStyles from "../styles";

const LanguageList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    settings: { locale },
  } = useSelector((state) => state);

  const handleChange = async (value) => {
    await dispatch(getLocale(value));
  };

  const menuItems = languageConfig.lanaguages.map(item => {
    return {
      name: item.language,
      value: item.code
    };
  });

  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
      }}
    >
      <Box>
        <SbuxSelect
          label="Language"
          menuItems={menuItems}
          defaultValue={locale}
          value={locale}
          handleSelectChange={handleChange}
        />
      </Box>

      <Divider sx={{ marginTop: 1 }} />
    </Box>
  );
};

export default LanguageList;
