import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "./styles";

const SbuxInputSearch = ({
  value,
  handleChange,
  handleClear,
  translatedSearchPlaceHolder = "Search",
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}
    >
      <TextField
        variant="outlined"
        size="small"
        value={value}
        onChange={handleChange}
        autoComplete="off"
        placeholder={`${translatedSearchPlaceHolder}...`}
        InputProps={{
          startAdornment: <SearchIcon fontSize="medium" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="medium"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={handleClear}
            >
              <ClearIcon fontSize="medium" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default SbuxInputSearch;
