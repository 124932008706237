import { Select, InputLabel, MenuItem } from "@mui/material";
import StyledSelect from "./styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl } from "@mui/material";
import useStyles from "./styles";

const SbuxSelect = ({
  menuItems,
  value,
  defaultValue,
  handleSelectChange,
  height = null,
  width = null,
  disabled = false,
  maxWidth = null,
  className,
  label,
  required,
  placeHolderText = "",
}) => {
  const classes = useStyles();

  const getMenuItems = () => {
    return (
      menuItems &&
      menuItems.length > 0 &&
      menuItems.map((menuItem, index) => (
        <MenuItem
          sx={{
            fontSize: 14,
          }}
          key={index}
          className={classes.menuItem}
          value={menuItem.value}
        >
          {menuItem.name}
        </MenuItem>
      ))
    );
  };
  const handleOnChange = (event) => {
    handleSelectChange(event.target.value);
  };

  const inputLabel = !!label ? (
    <InputLabel shrink>{label}</InputLabel>
  ) : undefined;

  return (
    <>
      <FormControl required={required} size="small" fullWidth>
        {inputLabel}
        <Select
          sx={{ maxHeight: 31 }}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          className={className}
          onChange={handleOnChange}
        >
          {getMenuItems()}
        </Select>
      </FormControl>
    </>
  );
};

export default SbuxSelect;
