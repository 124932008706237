import React from "react";
import { SvgIcon } from "@mui/material";
import { StyledGridOverlay } from "./styled";
import { CURRENT_TAB_NO_ROWS_OVERLAY } from "../../constants";
import SbuxTranslate from "../SbuxTranslate";
import useStyles from "./styles";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";

export const CustomNoRowsOverlay = React.memo(() => {
  const classes = useStyles();

  return (
    <StyledGridOverlay>
      <SvgIcon className={classes.sbuxLogo}>
        <SbuxLogo />
      </SvgIcon>

      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        <SbuxTranslate>
          {"Information will populate on an active contact"}
        </SbuxTranslate>
      </div>
    </StyledGridOverlay>
  );
});
