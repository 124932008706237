import React from "react";
import SbuxDialog2 from "../SbuxDialog2";
import SbuxTranslate from "../SbuxTranslate";
import { Box, Button, DialogContent, DialogActions, Typography } from "@mui/material";
import useStyles from "./styles";

const SbuxConfirmationDialog = ({
  open,
  toggleDialogOpen,
  handleOkClick,
  heading = "",
  content,
  okBtnText = "Okay",
  cancelBtnText = "Cancel",
}) => {
  const classes = useStyles();

  const dialogConfig = {
    title: <SbuxTranslate>{heading}</SbuxTranslate>,
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <SbuxDialog2
      open={open}
      config={dialogConfig}
      handleOnClose={toggleDialogOpen}
    >
      <DialogContent dividers>
        <Typography variant="h5">
          <SbuxTranslate>{content}</SbuxTranslate>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button variant="contained" size="medium" onClick={handleOkClick}>
            <SbuxTranslate>{okBtnText}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleDialogOpen}>
            <SbuxTranslate>{cancelBtnText}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default SbuxConfirmationDialog;
