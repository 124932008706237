import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontSize: 18,
  },
  dragTitle: {
    fontSize: 15,
  },
  dragSubTitle: {
    fontSize: 14,
    marginTop: -5,
  },
  elementRoot: {
    flex: 1,
  },
  elementTabName: {
    textAlign: "center",
    paddingTop: 3,
  },
  footerButton: {
    fontSize: 14,
    width: 90,
    height: 33,
    margin: 2,
    padding: 0,
  },
  dialogRoot: {
    minWidth: 500,
    minHeight: 120,
    zIndex: 1,
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogButton: {
    marginRight: 10,
  },
  actionButtons: {
    display: "flex",
    width: '100%',
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    gap: 10,
    justifyContent: "center",
    "& > button": {
      maxWidth: 130,
      [theme.breakpoints.down('sm')]: {
        maxWidth: "100%"
      }
    }
  },
  userId: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10
  }
}));
export default useStyles;
