import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import useStyles from './styles';

const SbuxTextField = ({
  value,
  name = null,
  variant = "outlined",
  label = null,
  disabled = null,
  placeholder = null,
  isError = null,
  errorMessage = null,
  required = null,
  handleChange,
  displayCounter = false,
  maxLength,
  multiline = false,
  rows = 4,
}) => {
  // let maxLength = multiline ? 2000 : 55;
  const classes = useStyles();

  const calcStyles = [];
  if (multiline) {
    calcStyles.push(classes.multiline);
  }

  return (
      <TextField
        label={label}
        variant={variant}
        name={name}
        disabled={disabled}
        value={value}
        className={calcStyles.join(' ')}
        placeholder={placeholder}
        onChange={handleChange}
        inputProps={maxLength}
        multiline={multiline}
        rows={multiline ? rows : 1}
        rowsMax={value ? 10 : 1}
        helperText={
          <Box className={classes.holder}>
            {isError ? (
              <span style={{ fontSize: 14, flex: 1 }}>{`${errorMessage}`}</span>
            ) : (
              ""
            )}
            {displayCounter && (
              <span className={classes.counter}
              >{`${value.length}/${maxLength}`}</span>
            )}
          </Box>
        }
        error={isError}
        required={required ? required : null}
      />
  );
};

export default SbuxTextField;
