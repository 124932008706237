import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootTab: {
    flex: 1,
    textAlign: "center",
    minWidth: "60px",
    padding: "10px 6px",
    "&.MuiTab-root.Mui-selected, &.MuiTab-root:hover": {
      background: `${theme.palette.darkGray.main} !important`,
      color: "#fff",
      boxShadow: "-7px 0 15px -4px #616161",
    },
    "&.MuiTab-root": {
      display: "flex",
      marginRight: 3,
      background: "rgb(242, 242, 242)",
      border: "1px solid rgba(0, 0, 0, 0.05)",
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      textTransform: "capitalize",
      textAlign: "center",
      minWidth: "75px",
      padding: "10px 6px",
      "&.MuiTab-root:last-of-type": {
        marginRight: 0,
      },
      "@media (max-width: 1024px)": {
        fontSize: 14,
        flex: 1,
      },
    },
    "&:focus": {
      outline: 0,
    },
  },
}));

export default useStyles;
