import { makeStyles } from "@mui/styles";
import { COLOR_GREEN_ACCENT, COLOR_RED } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    height: "auto",
    minHeight: 100,
    padding: 8,
  },
  row: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,

    "& > .MuiIconButton-root": {
      width: 30,
    },
    // dropdown & input
    "& > .MuiFormControl-root": {
      width: "auto",

      "& > .MuiInputBase-root": {
        padding: 0,
        width: 150,
        flexShrink: 0,
        marginRight: 8,

        "& > fieldset": {
          top: 0,
          height: 31,
        },
      },
    },

    "& > span": {
      flex: "1 1 auto",
      fontSize: 12,
      width: 100,
      maxWidth: 100,
      flexShrink: 0,
    },
    "& > .MuiInputBase-root": {
      padding: 0,
    },
    "& > .react-datepicker-wrapper": {
      width: "auto",
    },
  },
  datePicker: {
    padding: 4,
    width: 150,
    height: 32,
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    fontSize: 12,
  },
  filterBar: {
    height: 35,
    display: "flex",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 8,

    "& > .MuiIconButton-root": {
      outline: "none !important",
      color: "red",
    },

    "& > span.title": {
      fontSize: 12,
    },
  },
  actionButtons: {
    display: "flex",
    paddingTop: 8,
    justifyContent: "end",
    "& > button": {
      width: "auto",
      marginLeft: 8,
      flexShrink: 0,
    },
  },
  actionTop: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 8,
    "& > span": {
      fontSize: 12,
      width: 100,
      marginRight: 8,
    },
    "& > button": {
      width: "auto",
      marginLeft: 8,
      flexShrink: 0,
    },
  },
  badge: {
    backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
  },
  invisible: {
    visibility: "hidden",
  },
  validator: {
    outline: `0 !important`,
    color: `${COLOR_RED} !important`,
    "&:focus": {
      outline: 0,
    },
  },
  badge: {
    backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
  },
  invisible: {
    visibility: "hidden",
  },
  validator: {
    outline: `0 !important`,
    color: `${COLOR_RED} !important`,
    "&:focus": {
      outline: 0,
    },
  },
}));

export default useStyles;
