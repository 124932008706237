import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.darkGray[600],
  }
}));

export default useStyles;
