import ClosureMsgWeeklyCheckbox from "./ClosureMsgWeeklyCheckbox";
import { Box } from "@mui/material";
import useStyles from "./styles";

const ClosureMsgWeeklyFrequency = ({
  isEdit = false,
  weeklyRecDays,
  weeklyRecEveryInputValue,
  handleWeeklyRecEveryInputValueChange,
  handleCheckboxChange,
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: 1, marginLeft: 3 }}>
        <Box
          className={classes.rowMultiCtrl}
          sx={{
            display: "flex",
            height: 22,
          }}
        >
          <Box component="label">{"Recur every"}</Box>
          <Box>
            <input
              type={"number"}
              value={weeklyRecEveryInputValue}
              style={{
                width: 40,
                height: 24,
              }}
              onChange={(event) =>
                handleWeeklyRecEveryInputValueChange(event.target.value)
              }
            />
          </Box>
          <Box component="label">{"week(s) on:"}</Box>
        </Box>
      </Box>

      <Box sx={{ flex: 1, marginLeft: 3 }}>
        <ClosureMsgWeeklyCheckbox
          isEdit={isEdit}
          weeklyRecDays={weeklyRecDays}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  );
};

export default ClosureMsgWeeklyFrequency;
