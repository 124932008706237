import { makeStyles } from "@mui/styles";
import { COLOR_GREEN_ACCENT, COLOR_NEUTURAL_WARM } from "../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    gap: 10,
    "& > button": {
      width: 'auto',
    }
  },
  statusSpan: {
    textTransform: "none",
    marginLeft: "4px",
  },
  saveButton: {
    marginLeft: 6,
    fontSize: 13,
    height: 24,
    width: 80,
    paddingTop: 0,
    paddingBottom: 1,
    outline: "0 !important",
    backgroundColor: COLOR_GREEN_ACCENT,
    color: COLOR_NEUTURAL_WARM,
    "&:hover": {
      backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
      color: COLOR_NEUTURAL_WARM,
    },
  },
  cancelButton: {
    marginLeft: 6,
    fontSize: 13,
    height: 24,
    width: 80,
    paddingTop: 0,
    paddingBottom: 1,
    outline: "0 !important",
  },
}));
export default useStyles;
