import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import SbuxSelect from "../../../../../../components/SbuxSelect";
import { Box, FormControlLabel, Radio } from "@mui/material";
import useStyles from "./styles";

const ClosureMsgYearlyFrequency = ({
  yearlyOption,
  yearlyMonthList,
  yearlyRecWeekDaysList,
  yearlyWeekDaysList,
  handleYearlyOptionsValueChange,
  yearlyRecEveryInputValue,
  handleYearlyRecEveryInputValueChange,
  yearlyOption1MonthValue,
  handleYearlyOption1MonthValueChange,
  yearlyOption1OnInputValue,
  handleYearlyOption1OnInputValueChange,
  yearlyOption2MonthValue,
  handleYearlyOption2MonthValueChange,
  yearlyRecDayOfValue,
  handleYearlyRecDayOfValueChange,
  yearlyRecWeekDays,
  handleYearlyRecWeekDaysChange,
}) => {
  const classes = useStyles();

  return (
    <SbuxRadioGroup
      value={yearlyOption}
      handleRadioButtonChange={handleYearlyOptionsValueChange}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, marginLeft: 3, marginBottom: 0.8 }}>
          <Box className={classes.rowMultiCtrl}>
            <Box component="label">{"Recur every"}</Box>
            <Box>
              <input
                type={"number"}
                value={yearlyRecEveryInputValue}
                style={{
                  width: 40,
                  height: 24,
                }}
                onChange={(event) =>
                  handleYearlyRecEveryInputValueChange(event.target.value)
                }
              />
            </Box>
            <Box component="label">{"year(s)"}</Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1, marginLeft: 3, marginBottom: 0.5 }}>
          <FormControlLabel
            value={"yearlyOption1On"}
            control={<Radio />}
            label={
              <Box
                className={classes.rowMultiCtrl}
                sx={{
                  pointerEvents: yearlyOption !== "yearlyOption1On" && "none",
                  opacity: yearlyOption !== "yearlyOption1On" && 0.6,
                }}
              >
                <Box component="label">{"On"}</Box>

                <Box sx={{ minWidth: 105 }}>
                  <SbuxSelect
                    menuItems={yearlyMonthList}
                    defaultValue={yearlyOption1MonthValue}
                    value={yearlyOption1MonthValue}
                    handleSelectChange={(value) =>
                      handleYearlyOption1MonthValueChange(value)
                    }
                  />
                </Box>
                <Box>
                  <input
                    type={"number"}
                    value={yearlyOption1OnInputValue}
                    style={{
                      width: 40,
                      height: 24,
                    }}
                    onChange={(event) =>
                      handleYearlyOption1OnInputValueChange(event.target.value)
                    }
                  />
                </Box>
              </Box>
            }
          />
        </Box>

        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"yearlyOption2OnThe"}
            control={<Radio />}
            label={
              <Box
                className={classes.rowMultiCtrl}
                sx={{
                  pointerEvents:
                    yearlyOption !== "yearlyOption2OnThe" && "none",
                  opacity: yearlyOption !== "yearlyOption2OnThe" && 0.6,
                }}
              >
                <Box component="label">{"On the"}</Box>

                <Box
                  sx={{
                    minWidth: 90,
                  }}
                >
                  <SbuxSelect
                    menuItems={yearlyRecWeekDaysList}
                    defaultValue={yearlyRecDayOfValue}
                    value={yearlyRecDayOfValue}
                    handleSelectChange={(value) =>
                      handleYearlyRecDayOfValueChange(value)
                    }
                  />
                </Box>

                <Box
                  sx={{
                    minWidth: 115,
                  }}
                >
                  <SbuxSelect
                    menuItems={yearlyWeekDaysList}
                    defaultValue={yearlyRecWeekDays}
                    value={yearlyRecWeekDays}
                    handleSelectChange={(value) =>
                      handleYearlyRecWeekDaysChange(value)
                    }
                  />
                </Box>

                <Box component="label">{"of"}</Box>

                <Box
                  sx={{
                    minWidth: 105,
                  }}
                >
                  <SbuxSelect
                    menuItems={yearlyMonthList}
                    defaultValue={yearlyOption2MonthValue}
                    value={yearlyOption2MonthValue}
                    handleSelectChange={(value) =>
                      handleYearlyOption2MonthValueChange(value)
                    }
                  />
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default ClosureMsgYearlyFrequency;
