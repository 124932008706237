import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import useStyles from "./styles";

const SbuxDialog2 = ({ config, open, handleOnClose, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDialog, setOpenDialog] = useState(false);
  // Configuration
  const {
    title = "",
    subtitle,
    hasBackdrop = true,
    disableBackdropClose = true,
    disableEscapeKeyDown = true,
    asModal = false,
    noCloseButton = false,
    fullWidth = true
  } = config;

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleCloseEvent = (e, reason) => {
    if (disableBackdropClose && reason === "backdropClick") {
      return;
    }

    if (typeof handleOnClose === "function") {
      handleOnClose();
    }

    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      fullScreen={!asModal && fullScreen}
      fullWidth={fullWidth}
      onClose={handleCloseEvent}
      slotProps={{ backdrop: { invisible: !hasBackdrop } }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle>
        {title} {subtitle}
      </DialogTitle>
      {!noCloseButton && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseEvent}
        >
          <CloseIcon />
        </IconButton>
      )}

      {children}
    </Dialog>
  );
};

export default SbuxDialog2;
