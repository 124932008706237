import React from "react";
import { StyledGridOverlay } from "./styled";
import { ReactComponent as SbuxLogo } from "../../../../../../assets/image.svg";
import { SvgIcon } from "@mui/material";
import useStyles from './styles';

export const GenesysRecordingsOverlay = React.memo(() => {
  const classes = useStyles();

  return (
    <StyledGridOverlay>
      <SvgIcon className={classes.sbuxLogo}>
        <SbuxLogo />
      </SvgIcon>

      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        {"No data found"}
      </div>
    </StyledGridOverlay>
  );
});
