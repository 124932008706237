import { useState, useEffect } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import MediaMgtAdd from "../table/mediaMgt/MediaMgtAdd";
import MediaMgtEdit from "../table/mediaMgt/MediaMgtEdit";
import { Divider, Box, Button, DialogContent, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { createOrUpdateMediaMgt } from "../../../../services/mediaMgt";
import {
  MM_FILE_UPLOAD_OPTIONS_LIST,
  MM_DEFAULT_LANGUAGES,
  MEDIAMGT_FILE_TYPE_ERROR,
  MEDIAMGT_INVALID_FILE_ERROR,
  SNACK_BARS_SEVERITY_ERROR,
} from "../../../../constants";

import useStyles from "../styles";
import { getResetTextToSpeech } from "../../../../services/textToSpeech";

const MediaMgtDialog = ({
  title,
  open,
  toggleDialogOpen,
  row,
  languages,
  isAddNew,
  mediaContent,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultRow = {
    languageCode: "en",
    language: "English",
    promptName: "",
    fileName: "",
    description: "",
    fileContent: "",
  };
  const data = row && row.hasOwnProperty("languageCode") ? row : defaultRow;
  const [mediaMgtRow, setMediaMgtRow] = useState(data);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonList, setButtonList] = useState(MM_FILE_UPLOAD_OPTIONS_LIST);
  const [mediaInfo, setMediaInfo] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [formValidationError, setFormValidationError] = useState(false);
  const [textLengthValidationError, setTextLengthValidationError] =
    useState(false);

  useEffect(() => {
    setIsDialogOpen(open);
  }, [open]);
  useEffect(() => {
    if (mediaInfo && mediaInfo.hasOwnProperty("errorType")) {
      updateMediaMgtRow(true, null);
    } else if (mediaInfo && mediaInfo.fileContent) {
      updateMediaMgtRow(false, mediaInfo.fileContent, mediaInfo.isTextToSpeech);
    } else {
      updateMediaMgtRow(false, null);
    }
  }, [mediaInfo]);

  const updateMediaMgtRow = (errorType, fileContent, isTextToSpeech) => {
    if (errorType) {
      setMediaMgtRow({
        ...mediaMgtRow,
        errorType: mediaInfo.errorType,
      });
    } else if (fileContent) {
      setMediaMgtRow({
        ...mediaMgtRow,
        fileName: mediaMgtRow.fileName
          ? mediaMgtRow.fileName
          : mediaInfo.fileName,
        fileContent:
          mediaInfo.fileContent && mediaInfo.fileContent.split(`,`)[1],
        isTextToSpeech,
      });
      updateButtonList();
    } else {
      setMediaMgtRow({
        ...mediaMgtRow,
      });
      setButtonList(MM_FILE_UPLOAD_OPTIONS_LIST);
    }

    !errorType && mediaMgtRow.promptName
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
    if (!fileContent) {
      setButtonDisabled(true);
    }
    if (formValidationError) {
      setButtonDisabled(true);
    }
    setOpenSnackBar(errorType ? true : false);
  };

  const updateButtonList = () => {
    const result = buttonList.map((cur) => {
      if (
        (cur.value === 1 &&
          (mediaInfo.isRecorded || mediaInfo.isTextToSpeech)) ||
        (cur.value === 2 &&
          (mediaInfo.isUploaded || mediaInfo.isTextToSpeech)) ||
        (cur.value === 3 && (mediaInfo.isUploaded || mediaInfo.isRecorded))
      ) {
        return {
          ...cur,
          disabled: true,
          toolTipMsg: (
            <SbuxTranslate>{`To re-enable this tab, delete the ${
              mediaInfo.isRecorded
                ? "recorded"
                : mediaInfo.isTextToSpeech
                ? "text to speech"
                : "uploaded"
            } prompt.`}</SbuxTranslate>
          ),
        };
      } else {
        return cur;
      }
    });
    setButtonList(result);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleSave = async () => {
    const result = {
      languageCode: mediaMgtRow.languageCode,
      language: mediaMgtRow.language,
      promptName: mediaMgtRow.promptName,
      fileName: mediaMgtRow.promptName,
      description: mediaMgtRow.description,
      fileContent: mediaMgtRow.mediaId
        ? mediaMgtRow.fileContent || mediaContent.split(",")[1]
        : mediaMgtRow.fileContent,
      fileType: mediaMgtRow.isTextToSpeech ? "audio/mp3" : "audio/wav",
      mediaId: mediaMgtRow.mediaId,
    };
    dispatch(createOrUpdateMediaMgt(result));
    dispatch(getResetTextToSpeech());
    toggleDialogOpen();
  };

  const handleMediaMgtRow = (row, isError = false) => {
    const updatedRow = {
      ...mediaMgtRow,
      ...row,
    };
    setFormValidationError(isError);
    setMediaMgtRow(updatedRow);
    (updatedRow.promptName && updatedRow.fileName) ||
    (updatedRow.promptName && updatedRow.fileContent)
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
    if (isError) {
      setButtonDisabled(true);
    }
    if (isAddNew) {
      if (!mediaInfo || !mediaInfo.fileContent) {
        setButtonDisabled(true);
      }
    }
  };

  const handleDisableSaveBtn = (value) => {
    setTextLengthValidationError(value);
  };
  const handleMediaInfo = (value) => {
    setMediaInfo(value);
  };

  const handleLanguages = () => {
    let langugageList = [];
    if (row && row.hasOwnProperty("languageCode")) {
      langugageList.push({
        name: row.language,
        value: row.languageCode,
      });
    } else {
      const languageCodes = new Set(MM_DEFAULT_LANGUAGES.map((d) => d.value));
      langugageList = [
        ...MM_DEFAULT_LANGUAGES,
        ...languages.filter((f) => !languageCodes.has(f.value)),
      ];
    }
    return langugageList;
  };

  const dialogConfig = {
    title,
  };

  return (
    <>
      <SbuxDialog2
        open={isDialogOpen}
        config={dialogConfig}
        handleOnClose={toggleDialogOpen}
      >
        <DialogContent dividers>
          {isAddNew ? (
            <MediaMgtAdd
              toggleDialogOpen={toggleDialogOpen}
              languages={handleLanguages()}
              row={mediaMgtRow}
              handleMediaMgtRow={handleMediaMgtRow}
              handleMediaInfo={handleMediaInfo}
              buttonList={buttonList}
              handleDisableSaveBtn={handleDisableSaveBtn}
            />
          ) : (
            <MediaMgtEdit
              toggleDialogOpen={toggleDialogOpen}
              languages={handleLanguages()}
              row={mediaMgtRow}
              mediaContent={mediaContent}
              handleMediaMgtRow={handleMediaMgtRow}
              handleMediaInfo={handleMediaInfo}
              buttonList={buttonList}
              handleDisableSaveBtn={handleDisableSaveBtn}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Box className="actionContainer">
            <Button variant="contained" size="medium"
              onClick={handleSave}
              disabled={buttonDisabled || textLengthValidationError}
            >
              <SbuxTranslate>{"Save"}</SbuxTranslate>
            </Button>
            <Button variant="outlined" size="medium" onClick={toggleDialogOpen}>
              <SbuxTranslate>{"Cancel"}</SbuxTranslate>
            </Button>
          </Box>
        </DialogActions>
      </SbuxDialog2>

      {openSnackBar && mediaMgtRow.hasOwnProperty("errorType") && (
        <SbuxSnackbars
          width={380}
          open={openSnackBar}
          anchorOriginHorizontal={`center`}
          message={
            mediaMgtRow.errorType === "invalid"
              ? MEDIAMGT_INVALID_FILE_ERROR
              : MEDIAMGT_FILE_TYPE_ERROR
          }
          severity={
            mediaMgtRow.hasOwnProperty("errorType")
              ? SNACK_BARS_SEVERITY_ERROR
              : ""
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default MediaMgtDialog;
