import React from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import useStyles from "../../styles";

const DataManagementRow = ({
  row,
  handleEditRowClick,
  handleDeleteRowClick,
  columns,
}) => {
  const classes = useStyles();

  const {
    dataMgt: { metaConfigData, tableListData },
  } = useSelector((state) => state);

  const handleEditClick = () => {
    handleEditRowClick(row);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleDeleteRowClick(row);
  };

  const getTypeValue = (type) => {
    if (type === "true" || type === true) {
      return "true";
    }
    if (type === "false" || type === false) {
      return "false";
    }
    return type;
  };

  const renderValue = (row, cell) => {
    if (cell?.id !== "action") {
      if (cell?.type === "boolean") {
        return getTypeValue(row[cell?.id]);
      }
      return row[cell?.id];
    }
    return (
      <IconButton
        color="primary"
        className={classes.deleteButton}
        onClick={handleDeleteClick}
        disableFocusRipple
        disableRipple
      >
        <DeleteIcon />
      </IconButton>
    );
  };

  return (
    <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      {columns?.map((cell) => (
        <SbuxStyledTableCell
          key={cell.id}
          className={classes.cellFocus}
          onClick={cell?.id !== "action" ? handleEditClick : undefined}
          sx={{
            ...(cell?.id === "action" && {
              width: "100px",
              maxWidth: "100px",
            }),
          }}
        >
          {renderValue(row, cell)}
        </SbuxStyledTableCell>
      ))}
    </SbuxStyledTableRow>
  );
};

export default DataManagementRow;
