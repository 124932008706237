import { useState } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Divider,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import useStyles from "../styles";

const ClosureMsgEditDialog = ({
  title,
  open,
  handleRadioOkClick,
  toggleDialogOpen,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("singleInstance");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleOkayClick = () => {
    handleRadioOkClick(value);
  };

  const dialogConfig = {
    title,
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <SbuxDialog2
      open={open}
      config={dialogConfig}
      handleOnClose={toggleDialogOpen}
    >
      <DialogContent dividers>
        <Box>
          <Typography variant="h5">
            <SbuxTranslate>{`This is a single instance in a repeating series. What do you want to open? `}</SbuxTranslate>
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="singleInstance"
                control={<Radio />}
                label={<Box sx={{ fontSize: 14 }}>{"Just this one"}</Box>}
              />
              <FormControlLabel
                value="entireSeries"
                control={<Radio />}
                label={<Box sx={{ fontSize: 14 }}>{"The entire series"}</Box>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button variant="contained" size="medium" onClick={handleOkayClick}>
            <SbuxTranslate>{`Okay`}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleDialogOpen}>
            <SbuxTranslate>{`Cancel`}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default ClosureMsgEditDialog;
