import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import MediafileAutoComplete from "./MediafileAutoComplete";
import Skeleton from "@mui/material/Skeleton";
import { getTemporaryMsgMediaFile } from "../../../../services/temporaryMsg";
import { sortingCaseInSensitive } from "../utils";
import { useEffect, useState } from "react";
import useStyles from "../styles";
import { updateLanguageId } from "../../../../slices/mediaMgtSlice";

const TemporaryAudioConfig = ({
  languageId,
  handleLanguageMediaFile,
  row = null,
  disabled = false,
  maxWidth,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    mediaMgt: { mediaMgtData },
    temporaryMsg: { temporaryMsgMediaFileInfo, temporaryMsgStatus },
  } = useSelector((state) => state);

  const [mediaFilesOnLanguageId, setMediaFilesOnLanguageId] = useState([]);
  const [mediaFileInfo, setMediaFileInfo] = useState(null);
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [audioFileName, setAudioFileName] = useState("");
  const [hasError, setHasError] = useState(false);

  useEffect(async () => {
    let filteredData = null;
    if (languageId === null) {
      filteredData =
        languageId &&
        mediaMgtData.data.filter((item) => item.languageCode === "en");
    } else {
      filteredData =
        languageId &&
        mediaMgtData.data.filter((item) => item.languageCode === languageId);
    }
    dispatch(updateLanguageId(languageId));
    const result = filteredData.reduce((acc, cur) => {
      acc.push({
        value: cur.filePath,
        name: cur.fileName,
      });
      return acc;
    }, []);

    if (row) {
      const languages =
        Object.keys(row).length > 0 &&
        Object.keys(row).filter((a) => a.includes("message"));
      const mediaFileObj =
        row[languages.find((a) => a.split("_")[1] === languageId)];
      setSelectedMediaFile(
        mediaFileObj && mediaFileObj.value ? mediaFileObj.value : ""
      );
      mediaFileObj && handleMediaFileChange(mediaFileObj.value);
    }
    setMediaFilesOnLanguageId(result);
  }, [languageId]);

  useEffect(() => {
    if (
      temporaryMsgMediaFileInfo &&
      temporaryMsgMediaFileInfo.hasOwnProperty("mediaContent") &&
      temporaryMsgMediaFileInfo.mediaContent
    ) {
      setMediaFileInfo(temporaryMsgMediaFileInfo);
    }
  }, [temporaryMsgMediaFileInfo]);

  const playAudioFile = (file) => {
    return (
      <Box>
        {temporaryMsgMediaFileInfo === null &&
        temporaryMsgStatus !== "success" ? (
          <Box sx={{ width: "18vw", height: 26, maxWidth }}>
            <Skeleton animation="wave" height={26} />
          </Box>
        ) : (
          <audio
            key={Math.random()}
            style={{
              height: 26,
              maxWidth,
            }}
            controls
            title={audioFileName}
          >
            {selectedMediaFile &&
            temporaryMsgMediaFileInfo &&
            temporaryMsgMediaFileInfo.hasOwnProperty("languageCode") &&
            temporaryMsgMediaFileInfo.languageCode === languageId ? (
              <source src={file} type="audio/wav" />
            ) : (
              <source src={null} type="audio/wav" />
            )}
          </audio>
        )}
      </Box>
    );
  };

  const handleMediaFileChange = async (value, isAudioFileChanged = false) => {
    const filerData = mediaMgtData.data.find((item) => item.filePath === value);

    const data = {
      instanceId: filerData?.instanceId,
      languageCode: filerData?.languageCode,
      language: filerData?.language,
      fileName: encodeURIComponent(filerData?.filePath),
      mediaId: filerData?.mediaId,
      type: filerData?.type,
    };
    handleLanguageMediaFile(value, isAudioFileChanged);
    setSelectedMediaFile(value);
    setAudioFileName(filerData?.fileName);

    if (value) {
      await dispatch(getTemporaryMsgMediaFile(data));
    }
  };
  const handleAudioFileValueChange = (value) => {
    handleMediaFileChange(value, true);
  };

  const handleErrorChange = (errorState) => {
    setHasError(errorState);
  };

  return (
    <Box className={classes.audioFileContainer}>
      <Box>
        <MediafileAutoComplete
          label={"Audio File"}
          data={sortingCaseInSensitive(mediaFilesOnLanguageId)}
          handleSelectChange={handleAudioFileValueChange}
          defaultValue={selectedMediaFile}
          value={selectedMediaFile}
          disabled={disabled}
          required={true}
          onErrorChange={handleErrorChange}
        />
      </Box>

      <Box className={`${hasError ? classes.playerError : classes.player}`}>
        {mediaFileInfo &&
          mediaFileInfo.hasOwnProperty("mediaContent") &&
          playAudioFile(mediaFileInfo.mediaContent)}
      </Box>
    </Box>
  );
};

export default TemporaryAudioConfig;
