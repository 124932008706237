import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateIvrTable,
  setStatusUpdate,
} from "../../../../../services/ivrTable";

import useStyles from "../../styles";

const AdminIvrTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initTable = { tableName: "", partitionKey: "", sortKey: "" };
  const [table, setTabe] = useState(initTable);
  const [tableNameError, setTableNameError] = React.useState(false);
  const [partitionKeyError, setPartitionKeyError] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const {
    ivrTable: { statusCode, message },
  } = useSelector((state) => state);

  useEffect(() => {
    (statusCode === 200 || statusCode === 400) &&
      message.length > 0 &&
      setOpenSnackbar(true);
  }, [statusCode, message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTabe({ ...table, [name]: value });
    name === "tableName"
      ? setTableNameError(value.length < 1 ? true : false)
      : name === "partitionKey" &&
        setPartitionKeyError(value.length < 1 ? true : false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (table.tableName && table.partitionKey) {
      dispatch(createOrUpdateIvrTable(table));
    }
  };
  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    dispatch(setStatusUpdate());
  };

  const handleClear = () => {
    setTabe({ tableName: "", partitionKey: "", sortKey: "" });
    setTableNameError(false);
    setPartitionKeyError(false);
  };

  return (
    <>
      <Box className={classes.formContainer}>
        <Box
          component="form"
          className={classes.formControl}
          noValidate
          autoComplete="off"
        >
          <Box>
            <SbuxTextField
              label={"New Table Name"}
              handleChange={handleChange}
              value={table.tableName}
              name={"tableName"}
              isError={tableNameError}
              required
              focused
              errorMessage={
                tableNameError
                  ? "New Table Name required at least 1 character"
                  : ""
              }
            />
          </Box>

          <Box>
            <SbuxTextField
              label={"Partition Key"}
              handleChange={handleChange}
              value={table.partitionKey}
              name={"partitionKey"}
              isError={partitionKeyError}
              required
              errorMessage={
                tableNameError
                  ? "Partition Key required at least 1 character"
                  : ""
              }
            />
          </Box>

          <Box>
            <SbuxTextField
              label={"Sort Key"}
              handleChange={handleChange}
              value={table.sortKey}
              name={"sortKey"}
            />
          </Box>
        </Box>

        <Box className={classes.actionContainer}>
          <Button
            disabled={
              table.tableName.length === 0 || table.partitionKey.length === 0
            }
            variant="contained"
            size="medium"
            onClick={handleSubmit}
          >
            Create
          </Button>
          <Button variant="outlined" size="medium" onClick={handleClear}>
            Clear
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: "flex", marginTop: 2, marginLeft: 40 }}>
        {openSnackbar && (
          <SbuxSnackbars
            open={openSnackbar}
            anchorOriginVertical="bottom"
            anchorOriginHorizontal="left"
            message={
              statusCode === 200 ? "Success! Your table was created" : message
            }
            width={400}
            severity={statusCode === 200 ? "success" : "error"}
            autoHide={false}
            position="absolute"
            top={400}
            left={8}
            height={10}
            handleClose={handleClose}
          />
        )}
      </Box>
    </>
  );
};

export default AdminIvrTable;
