import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { typography } from "./typography";
import { components } from "./components";
import {
  primary,
  secondary,
  complementary,
  darkGray,
  redAlert,
  black,
  white,
} from "./color-palettes";

const themeOptions = {
  name: "Starbucks",
  palette: {
    mode: "light",
    primary,
    secondary,
    complementary: {
      ...complementary,
      contrastText: "#FFF",
    },
    darkGray,
    redAlert,
    black,
    white,
    error: {
      main: redAlert[800],
    },
    success: {
      main: primary[800],
    },
  },
  typography,
  // spacing: 4
  components,
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

export default theme;
