import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import CrateLayout from "./layouts/index";
import SbuxInstance from "./features/instance";
import { signIn } from "./services/auth";
import { default as theme } from './themes/sbux';
import "./index.css";



const App = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth);

  useEffect(async () => {
    if (status === "idle" || status === "rejected") {
      await dispatch(signIn(status));
    }
  }, [status]);

  return (
    <>
      {status === "success" && (
        <CrateLayout>
          <SbuxInstance />
        </CrateLayout>
      )}
    </>
  );
};

export default App;
